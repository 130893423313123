import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrackingServices from "../services/TrackingServices";
import { AgentItem } from "../components/agents";
import Spinner from "../components/general/Spinner";

const Agents = () => {
  // get location from url queries
  const params = useParams();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCurrentLocationAgents = async () => {
    setLoading(true);
    const currentLocationAgents = await TrackingServices.fetchLocationAgents(
      params?.id
    );

    setAgents(currentLocationAgents?.agents);
    setLoading(false);
  };

  useEffect(() => {
    getCurrentLocationAgents();
  }, []);
  return (
    <div className={"bg-slate-100 flex-1 min-h-screen md:py-4 relative"}>
      {/*  container  */}
      <div
        className={
          "bg-white md:w-11/12 xl:w-9/12 mx-auto p-5 grid md:grid-cols-2 gap-4 flex-1"
        }
      >
        {/*  agent item   */}
        {agents?.map((agent) => (
          <AgentItem key={agent?.id} agent={agent} />
        ))}
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default Agents;
