import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const AgentItem = ({ agent }) => {
  const navigate = useNavigate();

  const handleViewAgent = (id) => {
    navigate(`/agent/${id}`);
  };
  return (
    <div className={"bg-slate-50 flex items-center px-3 rounded-sm"}>
      {/*  image  */}
      <img
        src={
          "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
        }
        alt={"agent"}
        className={"h-44 w-48"}
      />

      <div className={"p-4 flex flex-col gap-2 "}>
        <div className={"flex gap-x-2 items-center"}>
          <h4 className={"text-base font-semibold text-black"}>Name:</h4>
          <p className={"text-base text-black"}>{agent?.business_name}</p>
        </div>
        <div className={"flex gap-x-2 items-center"}>
          <h4 className={"text-base font-semibold text-black"}>Location:</h4>
          <p className={"text-base text-black"}>
            {agent?.agent_locations?.name}
          </p>
        </div>

        <div className={"flex gap-x-2 items-center"}>
          <h4 className={"text-base font-semibold text-black"}>Status:</h4>
          <p
            className={`text-base ${
              !agent?.isOpen ? "text-green-600" : "text-gray-500"
            }`}
          >
            {!agent?.isOpen ? "Open" : "Closed"}
          </p>
        </div>
        <div className={"flex gap-x-2 items-center"}>
          <h4 className={"text-base font-semibold text-black"}>Closes at:</h4>
          <p className={"text-base text-black"}>{agent?.closing_hours} </p>
        </div>

        {/*  actions */}
        <div>
          <Button
            className={"bg-blue-800 text-white h-10 w-32"}
            onClick={() => handleViewAgent(agent.id)}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgentItem;
