import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrackingServices from "../services/TrackingServices";
import Spinner from "../components/general/Spinner";

const Agent = () => {
  // get location from url queries
  const params = useParams();
  const [agent, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCurrentAgent = async () => {
    setLoading(true);
    const { agent } = await TrackingServices.fetchAgent(params?.id);

    setAgent(agent);
    setLoading(false);
  };

  useEffect(() => {
    getCurrentAgent();
  }, [params?.id]);

  return (
    <div className={"bg-slate-100 flex-1 min-h-screen md:py-4 relative"}>
      {/*  container  */}
      <div className={"md:w-11/12 xl:w-6/12 mx-auto p-5 gap-4 flex-1"}>
        <h5 className={"text-2xl font-bold mb-3 text-center underline"}>
          {agent?.business_name}{" "}
        </h5>
        {/*  image*/}
        <div className={"bg-slate-50 flex justify-center rounded-xl"}>
          <img
            src={
              "https://static.vecteezy.com/system/resources/previews/002/041/652/original/motorcycle-delivery-driver-with-red-clothes-vector.jpg"
            }
            className={"h-[500px] w-full lg:w-1/2 object-contain"}
          />
        </div>

        <div className={"bg-white p-5 mt-5 rounded-xl flex flex-col gap-4"}>
          <div className={"flex gap-4"}>
            <h5 className={"font-medium text-base"}>Name: </h5>
            <h5 className={"text-base"}>{agent?.business_name} </h5>
          </div>

          <div className={"flex gap-4"}>
            <h5 className={"font-medium text-base"}>Description: </h5>
            <h5 className={"text-base"}>{agent?.agent_description} </h5>
          </div>
          <div className={"flex gap-4"}>
            <h5 className={"font-medium text-base"}>Location: </h5>
            <h5 className={"text-base"}>{agent?.agent_locations?.name} </h5>
          </div>

          <div className={"flex gap-4"}>
            <h5 className={"font-medium text-base"}>Status: </h5>
            <h5
              className={`font-medium text-base ${
                !agent?.isOpen ? "text-green-600" : "text-gray-500"
              }`}
            >
              {!agent?.isOpen ? "Open" : "Closed"}
            </h5>
          </div>

          <div className={"flex flex-col gap-3 mt-5"}>
            <h5 className={"font-medium text-lg"}>Working hours </h5>

            <div className={"flex gap-4 "}>
              <h5 className={"font-medium text-base opacity-70"}>
                Monday - Friday:{" "}
              </h5>

              <p className={""}>
                {agent?.opening_hours} - {agent?.closing_hours}
              </p>
            </div>
          </div>

          <div className={"flex flex-col gap-3 mt-5"}>
            <h5 className={"font-medium text-lg"}>Services Offered: </h5>

            <p className={"opacity-70"}>Package sending and recieving</p>
          </div>

          <div className={"flex flex-col gap-3 mt-5"}>
            <h5 className={"font-medium text-lg"}>Collection Period: </h5>

            <p className={"opacity-70"}>
              You have up to 3 days to collect your package at this agent
            </p>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default Agent;
