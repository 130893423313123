import React from "react";
import {
  FacebookFilledSvg,
  IgSvg,
  LinkedFilledSvg,
  TwitterFilledSvg,
  YoutubeFilledSvg,
} from "../../assets/svg";

const Footer = () => {
  return (
    <div
      className={
        "px-8 md:px-20 py-6 md:py-12 gap-y-8 bg-primary flex flex-col-reverse md:flex-row justify-between"
      }
    >
      <div className={"font-roboto font-[400] font-sm flex-1"}>
        <span>Pickup Mtaani @2023. All rights reserved.</span>
      </div>
      <div
        className={
          "hidden md:flex flex-1 gap-x-8 text-[#272D2D] text-base font-[500] font-roboto"
        }
      >
        <a href="https://pickupmtaani.com/terms.html">Privacy Policy </a>
        <a href="/terms">Terms and Conditions</a>
      </div>

      <div className={"flex justify-center md:justify-end gap-x-2 flex-1"}>
        <a href="https://www.youtube.com/@PickupMtaaniofficial">
          <YoutubeFilledSvg />
        </a>
        <a href="https://web.facebook.com/pickupmtaani/?_rdc=1&_rdr">
          <FacebookFilledSvg />
        </a>
        <a href="https://twitter.com/PMtaani?t=5sRBiLkDpW3zhm0Kxy20-w&s=31">
          <TwitterFilledSvg />
        </a>
        <a href="https://www.instagram.com/pickup_mtaani_cs/">
          <IgSvg />
        </a>
        <a href="https://www.linkedin.com/company/pick-up-mtaani/">
          <LinkedFilledSvg />
        </a>
      </div>
    </div>
  );
};

export default Footer;
