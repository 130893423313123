import React, { useState } from "react";
import {
  AndroidSVG,
  DownloadSVG,
  IosSVG,
  LogoSVG,
  MenuSvg,
  RightArrowSVG,
  SearchSVG,
} from "../../assets/svg";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ openSideNav }) => {
  const [state, setState] = useState("");
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleTrack = () => {
    // check if tracking number added
    if (!state || state === "") {
      window.alert("Tracking number required");
      return;
    }

    const trackingNumber = state?.split("-");
    //check tracking format
    if (trackingNumber?.length < 4) {
      window.alert(
        "Invalid tracking number. Please enter the correct tracking number"
      );
      return;
    }
    navigate(`/track-package?${state}`);
  };
  return (
    <div
      className={`py-2.5 px-8 flex fixed top-0 right-0 left-0 bg-white_bg z-[100] ${
        pathname === "/track-package" && "hidden"
      }`}
    >
      <LargeNav
        state={state}
        handleChange={(e) => setState(e.target?.value)}
        handleTrack={handleTrack}
      />
      <SmallNav handleOpen={openSideNav} />
    </div>
  );
};

export default Navbar;

const LargeNav = ({ state, handleChange, handleTrack }) => (
  <div
    className={
      "hidden md:flex flex-1 justify-between items-center font-lato font-[600] text-lg "
    }
  >
    <div>
      <LogoSVG />
    </div>
    {/*        nav list     */}
    <a
      className={
        "hover:border-b-[2px] border-b-[2px] border-white_bg hover:border-primary_300 text-grey_800"
      }
      href={"/#features"}
    >
      Features
    </a>
    <a
      className={
        "hover:border-b-[2px] border-b-[2px] border-white_bg hover:border-primary_300 text-grey_800"
      }
      href={"/#tracking"}
    >
      Track Package
    </a>

    <div
      className={
        "flex-[0.6] flex gap-3 bg-white h-full items-center border border-grey_300 rounded-lg pl-3.5 overflow-hidden"
      }
    >
      {/*    icon   */}
      <SearchSVG />

      <input
        className={
          "flex-1 h-full text-base font-[400] focus:outline-none text-grey_500"
        }
        placeholder={"Enter tracking code"}
        value={state}
        onChange={handleChange}
      />

      <button
        className={
          "h-full px-3 bg-grey_50 flex items-center justify-center outline-none"
        }
        onClick={handleTrack}
      >
        <RightArrowSVG color={"#667085"} />
      </button>
    </div>

    <a
      className={
        "hover:border-b-[2px] border-b-[2px] border-white_bg hover:border-primary_300 text-grey_800"
      }
      href={"/#testimonials"}
    >
      Testimonials
    </a>
    <a
      className={
        "hover:border-b-[2px] border-b-[2px] border-white_bg hover:border-primary_300 text-grey_800"
      }
      href={"/#about"}
    >
      About Us
    </a>

    <DownloadBtn />
  </div>
);

const SmallNav = ({ handleOpen }) => (
  <div
    className={
      "flex md:hidden flex-1 justify-between items-center font-lato font-[600] text-lg "
    }
  >
    <div>
      <LogoSVG />
    </div>
    {/*        nav list     */}

    <button onClick={handleOpen}>
      <MenuSvg />
    </button>
  </div>
);
const DownloadBtn = () => {
  const [showCard, setShow] = useState(false);
  return (
    <div
      className={"relative"}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <button
        className={
          "rounded-lg bg-black text-primary_300 flex gap-2 h-9 items-center justify-center px-3.5 text-sm hover:opacity-80"
        }
      >
        Download
        <DownloadSVG />
      </button>

      <DownloadHoverCard showCard={showCard} />
    </div>
  );
};

const DownloadHoverCard = ({ showCard }) => (
  <div
    className={`rounded-lg absolute left-0 right-0 -top-0 z-10 cursor-pointer `}
  >
    <div className="h-10 w-20" />
    <div
      className={`bg-white rounded-lg shadow-md overflow-hidden ${
        showCard ? "flex flex-col" : "hidden"
      }   `}
    >
      <DownloadHoverItem
        icon={<AndroidSVG />}
        text={"Android"}
        link={"https://play.google.com/store/apps/details?id=com.pum_vendor"}
      />
      <DownloadHoverItem
        link={"https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"}
        icon={<IosSVG />}
        text={"iPhone"}
      />
    </div>
  </div>
);
export const DownloadHoverItem = ({ icon, text, link, className }) => (
  <a href={link}>
    <div
      className={`h-10 flex items-center justify-center gap-x-2 border border-bottom-grey_100 md:hover:bg-grey_100 ${className}`}
    >
      {icon}
      <span className={"font-inter font-[500] text-sm"}>{text}</span>
    </div>
  </a>
);
